import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决路由重复问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'homePage',
    component: () => import(/* webpackChunkName: "homePage" */ '../components/homePage/homePage.vue')

  },
  // 详情
  {
    path: '/prdDetail',
    name: 'prdDetail',
    component: () => import(/* webpackChunkName: "prdDetail" */ '../components/prdDetail/prdDetail.vue')
  },
  // 个人中心
  {
    path: '/personCenter',
    name: 'personCenter',
    component: () => import(/* webpackChunkName: "personCenter" */ '../components/personCenter/personCenter.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
