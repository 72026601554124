import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {

    },
    mutations: {
        // add5RMB(state) {
        //     state.count += 5
        // },
        // add10RMB(state) {
        //     state.count += 10
        // },
        // clearRMB(state, data) {
        //     state.count = data
        // }
    },
    actions: {
        // ctx实际是个对象: {commit,dispatch...}
        // clearRMB({commit}) {
        //     setTimeout(() => {
        //         commit('clearRMB',-1)
        //     }, 2000);
        // },
        // clearRMB(ctx) {
        //     setTimeout(() => {
        //         ctx.commit('clearRMB')
        //     }, 2000);
        // }
    }
})