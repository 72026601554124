import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import store from "./store/index";  // vuex
import router from './router'
/**
 * v-md-editor
 * markdown 预览组件
 * 主题，选一个就行：vuepressTheme 、 githubTheme
 * 地址：https://ckang1229.gitee.io/vue-markdown-editor/zh/examples/preview-demo.html#%E9%A2%84%E8%A7%88%E7%BB%84%E4%BB%B6
 */
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
// import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
// import '@kangc/v-md-editor/lib/theme/style/github.css';
import hljs from 'highlight.js';
VMdPreview.use(
  // githubTheme,
  vuepressTheme,
  {
    Hljs: hljs,
  });

Vue.use(VMdPreview);

window.eventBus = new Vue();
Vue.config.productionTip = false

Vue.use(ElementUI);

// 自定义指令v-focus
// Vue.directive('focus', {
//   inserted: function (el, binding, vnode, oldVnode) {
//     console.log([
//       {
//         el,
//         binding,
//         vnode,
//         oldVnode
//       }
//     ])

//     el.focus()
//   },
//   bind: function () {

//   },
//   update: function () {

//   }
// })

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
